const getHeightBasedOnWindow = (width: number): number => {
  if (width > 1440) {
    return 300;
  } else if (width >= 769 && width <= 1440) {
    return 225;
  } else if (width >= 481 && width <= 768) {
    return 195;
  } else {
    return 180;
  }
};

export default getHeightBasedOnWindow;
