const getWidthBasedOnWindow = (width: number): number => {
  if (width > 1440) {
    return 535;
  } else if (width >= 769 && width <= 1440) {
    return 400;
  } else if (width >= 481 && width <= 768) {
    return 350;
  } else {
    return 320;
  }
};

export default getWidthBasedOnWindow;
