"use client";
import Link from "next/link";
import { LINKS, SOCIAL_LINKS } from "@/utils/constants";
import { YouTubeEmbed } from "@next/third-parties/google";
import useWindowWidth from "@/hooks/useWindowWidth";
import getWidthBasedOnWindow from "@/utils/getWidthBasedOnWindow";
import getHeightBasedOnWindow from "@/utils/getHeightBasedOnWindow";

const HomeContent = () => {
  // Using the custom hook to check if window width is less than 768px
  const width = useWindowWidth();
  const adjustedWidth = getWidthBasedOnWindow(width);
  const adjustedHeight = getHeightBasedOnWindow(width);
  return (
    <div className="max-w-screen-xl py-12 px-4">
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
        <div>
          <h2 className="mb-4 p-0 border-0 text-2xl	leading-custom-line text-main-red font-raleway font-medium">
            Why choose us as your independent health insurance agent in San Tan
            Valley?
          </h2>
          <p className="mb-3 text-main-gray">
            Debra Mikus is an independent health insurance agent in Arizona with
            Simpler Horizons Insurance Solutions Medicare Options, representing
            most major Medicare Advantage & Medicare Supplement Insurance plans
            in the San Tan Valley area. We offer our services at NO COST to you:
          </p>
          <ul className="space-y-1 text-main-gray list-disc list-inside sm:pl-5 mb-3.5 ml-3.5 ">
            <li>Personal appointments with you and your spouse</li>
            <li>Reviewing co-pays</li>
            <li>Explaining the limitations of each plan</li>
            <li>
              Providing detailed information on time frames and enrollment
              periods
            </li>
            <li>
              Assuring that your plan covers your medications at the lowest cost
            </li>
            <li>Finding plans that are accepted by your physicians</li>
            <li>
              Finding plans that are accepted by the hospital of your choice
            </li>
          </ul>
        </div>
        <div className="mb-3 flex flex-col space-y-4  sm:justify-center  mx-auto">
          <YouTubeEmbed
            videoid="Q-Ny-6Lzp8w"
            height={adjustedHeight}
            width={adjustedWidth}
            params="controls=0"
          />
          <div className="flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0 mt-5">
            <Link
              href={LINKS.CONTACT}
              className="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-main-blue hover:bg-main-red focus:ring-4 focus:ring-blue-300 h-12 mx-3"
            >
              Contact Us Today
            </Link>
            <a
              href={SOCIAL_LINKS.applyLink}
              className="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-main-blue hover:bg-main-red focus:ring-4 focus:ring-blue-300 h-12 mx-3"
              target="_blank"
              rel="noopener noreferrer"
            >
              Apply Online!
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HomeContent;
