import { useState, useEffect } from "react";

// Custom hook to get the current window width
const useWindowWidth = (): number => {
  const [width, setWidth] = useState<number>(0); // State to store the current window width

  useEffect(() => {
    // Handler to call on window resize
    const handleResize = () => {
      // Set window width to state
      setWidth(window.innerWidth);
    };

    // Set initial width
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures this effect runs only once on mount and unmount

  return width; // Return the current window width
};

export default useWindowWidth;
